import { Component, Vue } from "vue-property-decorator";
import { IPaymentDocument } from "@/interfaces/IPaymentDocument";
import Table from "@/components/Table.vue";
import _ from "lodash";
import { getFile } from "@/utils/File";
import { getFileWithBlob } from "@/utils/File";
import { namespace } from "vuex-class";
import { CANCELABLE_INVOICE } from "@/constants/PaymentDocumentType";

const PaymentDocumentModule = namespace("payment-document");
const EmailsModule = namespace("emails");

@Component
export default class PaymentDocumentsMixin extends Vue {
  protected filtered = "";

  @PaymentDocumentModule.Action("findAll")
  public findAllPaymentDocuments: any;

  @PaymentDocumentModule.Getter("getDataList")
  public paymentDocuments: any;

  @PaymentDocumentModule.Getter("getIsLoading")
  public paymentDocumentsLoading: any;

  @EmailsModule.Action("setBlobFile")
  private setCurrentBlobFile: any;

  protected selectedPaymentDocuments: Array<IPaymentDocument> = [];

  private blobSrc = "";
  public paymentDocSource = "";
  public paymentDocBlob: any = null;
  public paymentDocMimeType: any = null;
  public paymentDocumentVisible = false;
  public visibleEmailForm = false;

  private lastFetchStudentId = 0;
  protected selectedRow: any = null;
  protected cancelInvoiceModalId: any = "cancel-invoice-modal-id";
  protected documentLoading = false;

  public requestQuery(args?: any): void {
    this.findAllPaymentDocuments({
      resource: "payment-documents",
    });
  }

  protected onSelectedResources(selectedEducations: Array<any>): void {
    this.selectedPaymentDocuments = selectedEducations;
    if (selectedEducations && selectedEducations.length > 0) {
      const lastIndex = selectedEducations.length - 1;
      this.selectedRow = selectedEducations[lastIndex];
    }
  }

  protected onChangeFilter(): void {
    (this.$refs.educationTable as Table).onSearch(this.filtered);
  }

  private get lastSelectedPaymentDocument(): IPaymentDocument | null {
    return _.last(this.selectedPaymentDocuments) || null;
  }

  private get lastSelectedPaymentDocumentNumber(): string | null {
    return this.lastSelectedPaymentDocument?.paymentDocumentNumber || null;
  }

  private get lastSelectedPaymentDocumentStudentDocumentId(): number | null {
    return this.lastSelectedPaymentDocument?.studentDocumentId || null;
  }

  protected get selectedRowPaymentDocumentNumber(): string | null {
    return this.selectedRow?.paymentDocumentNumber || null;
  }

  protected get selectedPaymentDocumentsIds(): Array<number> {
    return _.reduce(
      this.selectedPaymentDocuments,
      (ids: Array<number>, resource: any) => {
        if (resource.studentDocumentId) ids.push(resource.studentDocumentId);
        return ids;
      },
      []
    );
  }

  protected onRowClicked(selectedRow: any): void {
    this.selectedRow = selectedRow;
    this.visibleEmailForm = false;

    let documentUrl;
    if (selectedRow.studentDocumentId) {
      documentUrl = `/student-documents/${selectedRow.studentDocumentId}`;
    } else {
      documentUrl = `/cost-bearer-documents/${selectedRow.costBearerDocumentId}`;
    }

    getFile(
      {
        method: "GET",
        url: `${documentUrl}`,
      },
      false
    ).then((blob: string) => {
      this.blobSrc = blob;
      this.paymentDocumentVisible = true;
    });
  }

  protected onDocRowClicked(selectedRow: any): void {
    this.onDocumentClose();
    this.onEmailAbort();
    this.selectedRow = selectedRow;
    let documentUrl;
    if (selectedRow.studentDocumentId) {
      documentUrl = `/student-documents/${selectedRow.studentDocumentId}`;
    } else {
      documentUrl = `/cost-bearer-documents/${selectedRow.costBearerDocumentId}`;
    }
    this.documentLoading = true;
    getFileWithBlob(
      {
        method: "GET",
        url: `${documentUrl}`,
      },
      false
    )
      .then((blob: any) => {
        this.blobSrc = blob.url;
        this.paymentDocumentVisible = true;

        this.paymentDocSource = blob.url;
        this.paymentDocBlob = blob.blob;
        this.paymentDocMimeType = blob.mimeType;
        this.setCurrentBlobFile(blob);
      })
      .finally(() => {
        this.documentLoading = false;
      });
  }

  protected async onPrint(): Promise<void> {
    if (!this.selectedPaymentDocumentsIds.length) {
      this.$toasted.info(this.$t("automatic_payment.selected_students_have_not_document").toString());
      return;
    }
    await getFile({
      method: "POST",
      url: `/payment-documents/print-preview`,
      data: { studentDocumentIds: this.selectedPaymentDocumentsIds },
    });
  }

  protected onCancelInvoice(): void {
    if (!this.selectedIsCancelable || this.selectedPaymentDocuments.length > 1) return;
    this.$bvModal.show(this.cancelInvoiceModalId);
  }

  protected get selectedIsCancelable(): boolean {
    return Boolean(this.selectedPaymentDocuments[0]?.paymentDocumentId && CANCELABLE_INVOICE.includes(this.selectedPaymentDocuments[0]?.paymentDocumentType));
  }

  protected onEmailFormOpen() {
    this.visibleEmailForm = true;
    this.paymentDocumentVisible = false;
  }

  protected onDocumentClose() {
    this.paymentDocumentVisible = false;
    this.visibleEmailForm = false;
    this.paymentDocSource = "";
  }

  protected onEmailAbort() {
    this.visibleEmailForm = false;
    this.paymentDocumentVisible = false;
    this.paymentDocSource = "";
  }
}
